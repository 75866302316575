// src/CommandLineInterface.js
import { wait } from '@testing-library/user-event/dist/utils';
import React, { useState, useEffect, useRef } from 'react';

const CommandLineInterface = () => {
    const [input, setInput] = useState('');
    const [output, setOutput] = useState(['Welcome to my portfolio! Type "help" for a list of commands.']);
    const [textColor, setTextColor] = useState('limegreen'); // State for text color
    const inputRef = useRef(null);

    useEffect(() => {
        inputRef.current.focus();
    }, []);

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && input.trim() !== '') {
            const newOutputLine = `visitor@recker.codes:~$ ${input}`;
            // Add the input command to the output
            setOutput(prevOutput => [...prevOutput, newOutputLine]);
            // Then process the command
            processCommand(input.trim());
            setInput(''); // Clear the input after processing
        }
    };

    const aboutMe = () => {
        return (
            <div>
                <h2>About Me</h2>
                <p>
                    I'm a half-stack developer with a passion for breaking down things and figuring out how they work.
                    One of my favorite things to do in my spare time is reverse engineering public API's and creating thing to break/abuse them.
                    I have experience with Python, java, C#, Node, Js, React, Ruby, Julia, Mojo, and more. I'm always eager to learn new
                    technologies to expand my ability to break random shit. I have an unrelenting desire to just break everything I see even the smallest flaw in.
                </p>
                <h2>My Aspirations</h2>
                <p>
                    My main goal it to blow up, then act like I don't know nobody, ha ha ha ha ha ha. No but fr i want to learn more about reinforcement learning and 
                    shit and eventually the main goal it to go work at comma.ai or something; Fuck tesla. uhh I havent really thought about it much beyond that though.
                    Idk why you're still reading this its random bs about some random dude on the internet, get a life.
                    
                 </p>
            </div>
        );
    }

    const ContactMe = () => {
        return (
            <div className='contact-container'>
                <h2>Contact Me</h2>
                <button className = "contact-button" onClick={() => window.location.href = "mailto:Vladimir0x45@protonmail.com"}>
                <img className='contact-svg' src={`${process.env.PUBLIC_URL}/email.svg`} alt="Email" style={{width: '50px', height: '50px'}} />
                </button>
                <button className='contact-button' onClick={() => window.location.href = 'https://discordapp.com/users/751267871342198833'}>
                <img className='contact-svg' src={`${process.env.PUBLIC_URL}/discord.svg`} alt="Discord" style={{width: '50px', height: '50px'}} />
                </button>
            </div>
          );
      };

    const processCommand = (command) => {
        let commandOutput = '';
        switch (command.toLowerCase()) {
            case 'help':
                setOutput(prevOutput => [...prevOutput, 
                    "Available commands:\n",
                    "help:",
                    "    Show available commands\n",
                    "clear:",
                    "    Clear the console\n",
                    "portfolio:",
                    "    Open my portfolio\n",
                    "about:",
                    "    Learn more about me\n",
                    "contact:",
                    "    Get in touch with me\n",
                    "git:",
                    "    Open my github profile\n",
                    "color <color>:",
                    "    Change the console text color"
                ]);
                return; // Return early to prevent adding commandOutput to the output
            case 'clear':
                setOutput([]);
                return; // Use return here to exit in case of 'clear'
            case 'portfolio' :
                commandOutput = `Navigating to portfolio...`;
                window.location.href = window.location +'portfolio';
                break;
            case 'about':
                commandOutput = aboutMe();
                break;
            case 'contact':
                commandOutput = ContactMe();
                break;
            case 'git':
                commandOutput = `Navigating to github...`;
                window.location.href = 'https://github.com/Vladimir-0001';
                break;
            case command.match(/^color /)?.input:
                const color = command.split(' ')[1];
                setTextColor(color);
                commandOutput = `Text color changed to ${color}.`;
                break;
            default:
                commandOutput = 'Command not found. Type "help" for a list of commands.';
        }
        // Append the command's output after processing if not handled already
        if (commandOutput) {
            setOutput(prevOutput => [...prevOutput, commandOutput]);
        }
    };

    const focusInput = () => {
        inputRef.current.focus();
    };

    return (
        <div className="cli" onClick={() => inputRef.current.focus()}>
            <div className="cli-header">Recker.codes Terminal</div>
            <ul>
                {output.map((line, index) => (
                    <li key={index} className='cli-output' style={{ color: textColor }}>{line}</li>
                ))}
            </ul>
            <div className="cli-input-prompt">
                <span style={{ color: textColor }}>visitor@recker.codes:~$ </span>
                <input
                    ref={inputRef}
                    type="text"
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    onKeyDown={handleKeyDown}
                    className="cli-input"
                    style={{ color: textColor }}
                />
            </div>
        </div>
    );
};

export default CommandLineInterface;
